<template>
  <div>
    <b-row>
      <b-col>
        <section
          id="dashboard-ecommerce"
        >
          <b-card
            v-if="loadFaturamentoMes"
            id="space-h"
            style="width: 100%; height: 400px;"
          ><b-overlay
            :show="loadFaturamentoMes"
            style="margin-top: 200px;"
          />
          </b-card>
          <b-card
            v-if="dadosGraficosFaturamentoMes"
            :title="`Faturamento por Ano - R$ ${valorBr(totalFaturamentoMes)}`"
          >
            <b-col
              md="2"
              class="budget-wrapper"
            >
              <v-select
                v-model="selectedYear"
                class="mt-2"
                placeholder="2023"
                :options="years"
                @input="getYear"
              />
            </b-col>
            <vue-apex-charts
              type="line"
              height="350"
              :options="chartOptionsFaturamentoMes"
              :series="seriesFaturamentoMes"
            />
          </b-card>
        </section>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BOverlay,
} from 'bootstrap-vue'

import VueApexCharts from 'vue-apexcharts'
import vSelect from 'vue-select'

import api from '../../../../../../axios-auth'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    VueApexCharts,
    vSelect,
    BOverlay,
  },
  props: {
    generalData: {
      type: Number,
      default: () => {},
    },
  },
  data() {
    return {
      dadosGraficosFaturamentoMes: false,
      loadFaturamentoMes: true,
      totalFaturamentoMes: 0,
      selectedYear: null,
      years: [2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023],
      seriesFaturamentoMes: [{
        name: 'Q° de Clientes',
        type: 'column',
        data: [],
        color: '#ff6900',
      }, {
        name: 'Q° de Parcelas',
        type: 'column',
        data: [],
        color: '#6610f2',
      }, {
        name: 'Valor Realizado',
        type: 'line',
        data: [],
        color: '#00d084',
      }],
      chartOptionsFaturamentoMes: {
        chart: {
          height: 350,
          type: 'line',
          stacked: false,
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          width: [1, 1, 4],
        },
        title: {
          text: '',
          align: 'left',
          offsetX: 110,
        },
        xaxis: {
          categories: [],
        },
        yaxis: [
          {
            axisTicks: {
              show: true,
            },
            axisBorder: {
              show: true,
              color: '#ff9f43',
            },
            labels: {
              style: {
                colors: '#008FFB',
              },
            },
            title: {
              text: 'Q° de Clientes',
              style: {
                color: '#008FFB',
              },
            },
            tooltip: {
              enabled: true,
            },
          },
          {
            seriesName: 'Q° de Clientes',
            opposite: true,
            axisTicks: {
              show: true,
            },
            axisBorder: {
              show: true,
              color: '#6610f2',
            },
            labels: {
              style: {
                colors: '#00E396',
              },
            },
            title: {
              text: 'Q° de Parcelas',
              style: {
                color: '#00E396',
              },
            },
          },
          {
            seriesName: 'Valor Realizado',
            opposite: true,
            axisTicks: {
              show: true,
            },
            axisBorder: {
              show: true,
              color: '#6610f2',
            },
            labels: {
              style: {
                colors: '#FEB019',
              },
            },
            title: {
              text: 'Valor Realizado',
              style: {
                color: '#6610f2',
              },
            },
          },
        ],
        tooltip: {
          fixed: {
            enabled: true,
            position: 'topLeft', // topRight, topLeft, bottomRight, bottomLeft
            offsetY: 30,
            offsetX: 60,
          },
        },
        legend: {
          horizontalAlign: 'left',
          offsetX: 40,
        },
      },
    }
  },

  created() {
    this.getGraficoFaturamentoMes(2023)
  },

  methods: {
    clearGraficoFaturametoMes() {
      this.chartOptionsFaturamentoMes.xaxis.categories = []
    },
    converterNumeroMes(mes) {
      if (mes === '1') { return 'JANEIRO' }
      if (mes === '2') { return 'FEVEREIRO' }
      if (mes === '3') { return 'MARÇO' }
      if (mes === '4') { return 'ABRIL' }
      if (mes === '5') { return 'MAIO' }
      if (mes === '6') { return 'JUNHO' }
      if (mes === '7') { return 'JULHO' }
      if (mes === '8') { return 'AGOSTO' }
      if (mes === '9') { return 'SETEMBRO' }
      if (mes === '10') { return 'OUTUBRO' }
      if (mes === '11') { return 'NOVEMBRO' }
      if (mes === '12') { return 'DEZEMBRO' }
      return mes
    },
    getYear(item) {
      if (item) {
        this.dadosGraficosFaturamentoMes = false
        this.loadFaturamentoMes = true
        this.getGraficoFaturamentoMes(item)
      }
    },
    getGraficoFaturamentoMes(years) {
      this.totalFaturamentoMes = 0
      this.seriesFaturamentoMes[0].data = []
      this.seriesFaturamentoMes[1].data = []
      this.seriesFaturamentoMes[2].data = []
      this.chartOptionsFaturamentoMes.xaxis.categories = []
      const body = {
        credores: [this.generalData],
        ano: years,
      }
      api.post('api/v1/credores/faturamento/geral/mes', body, {
        headers: {
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
          accept: 'application/json',
        },
      }).then(res => {
        const dt = res.data.dados
        this.dadosGraficosFaturamentoMes = true
        this.loadFaturamentoMes = false
        dt.map(cliente => {
          this.seriesFaturamentoMes[0].data.push(cliente.total_clientes)
        })
        dt.map(parcelas => {
          this.seriesFaturamentoMes[1].data.push(parcelas.total_parcelas)
        })
        dt.map(valor => {
          this.seriesFaturamentoMes[2].data.push(this.valorBr(valor.total))
        })
        dt.map(mes => {
          this.chartOptionsFaturamentoMes.xaxis.categories.push(this.converterNumeroMes(mes.mes))
        })
        dt.map(soma => {
          this.totalFaturamentoMes += parseFloat(soma.total)
        })
      })
    },
  },
}

</script>

<style lang="scss">
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
</style>
