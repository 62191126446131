<template>
  <div>
    <b-row
      align-h="end"
      class="mb-1"
    >
      <b-col
        cols="auto"
      >
        <b-button
          variant="primary"
          @click="$refs.representanteModal.show()"
        >
          <feather-icon
            icon="PlusIcon"
            class="mr-50"
          />
          Representante
        </b-button>
      </b-col>
    </b-row>
    <b-card
      title="Representantes"
    >
      <b-table
        small
        striped
        responsive
        :fields="fields"
        :items="representantes"
      >
        <template #cell(relatorios)="data">
          <div
            v-for="relatorio in data.item.relatorios"
            :key="relatorio.id"
          >
            {{ relatorio.title }}
          </div>
        </template>
        <template #cell(telefone)="data">
          <div
            v-for="phone in JSON.parse(data.item.telefones)"
            :key="phone"
          >
            {{ phone }}
          </div>
        </template>
        <template #cell(opcoes)="data">
          <b-row>
            <b-col cols="auto">
              <feather-icon
                icon="EditIcon"
                class="color-icon"
                @click="detailRepresentante(data.item)"
              />
            </b-col>
            <b-col cols="auto">
              <feather-icon
                icon="Trash2Icon"
                class="trash-icon"
                @click="deleteRepresentante(data.item)"
              />
            </b-col>
          </b-row>
        </template>
      </b-table>
    </b-card>
    <b-modal
      ref="representanteModal"
      hide-footer
      @hide="clearModal"
      no-close-on-backdrop
      no-close-on-esc
    >
      <b-row
        class="mb-2"
      >
        <b-col
          cols="12"
          class="mt-1"
        >
          Departamento:
          <b-form-select
            v-model="cad.departamento"
            :options="optionsList"
          />
        </b-col>

        <b-col
          cols="12"
          class="mt-1"
        >
          Nome:
          <b-form-input
            v-model="cad.nome"
            required
          />
        </b-col>
        <b-col
          cols="10"
          class="mt-1"
        >
          Telefone:
          <cleave
            v-for="(item, index) in telefoneRepresentantes"
            :key="index"
            v-model="cad.telefone[index]"
            class="form-control mt-1"
            :raw="false"
            :options="mask.telefone"
            placeholder="(99) 99999-9999"
          />
        </b-col>
        <b-col
          class="mt-5"
        >
          <feather-icon
            icon="PlusCircleIcon"
            size="20"
            class="color-icon"
            @click="repeateAgain"
          />
          <feather-icon
            icon="XIcon"
            class="ml-1 mr-25 color-icon"
            @click="removeAgain"
          />
        </b-col>
        <b-col
          cols="12"
          class="mt-1"
        >
          Email:
          <b-form-input
            v-model="cad.email"
            required
          />
        </b-col>

        <b-col
          cols="12"
          class="mt-1"
        >
          Receber Emails:
          <v-select
            v-model="cad.relatorios"
            multiple
            label="title"
            :options="optionsMultList"
          />
          <b-col
            cols="12"
            class="mt-1"
          >
            Observações:
            <b-form-textarea
              v-model="cad.observacoes"
              required
            />
          </b-col>
        </b-col>
      </b-row>
      <hr>
      <b-row
        align-h="end"
      >
        <b-col
          v-if="!editModal"
          cols="auto"
        >
          <b-button
            variant="primary"
            @click="storeRepresentante"
          >
            Cadastrar
          </b-button>

        </b-col>
        <b-col
          v-if="editModal"
          cols="auto"
        >
          <b-button
            variant="primary"
            @click="updateRepresentante"
          >
            Salvar
          </b-button>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>
<script>
import {
  BRow,
  BCol,
  BButton,
  BCard,
  BTable,
  BFormTextarea,
  BFormInput,
  BModal,
  BFormSelect,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Cleave from 'vue-cleave-component'
import axios from '@/../axios-auth'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BCard,
    BTable,
    BFormTextarea,
    BFormInput,
    BModal,
    vSelect,
    BFormSelect,
    Cleave,
  },
  data() {
    return {
      editModal: false,
      idRepresentante: '',
      cad: {
        relatorios: '',
        departamento: '',
        nome: '',
        telefone: [],
        email: '',
        observacoes: '',
        credor_id: this.$route.params.id,
      },
      fields: [
        { key: 'departamento', label: 'Departamento' },
        { key: 'nome', label: 'Nome' },
        { key: 'telefone', label: 'Telefone' },
        { key: 'email', label: 'Email' },
        { key: 'observacoes', label: 'Observações' },
        { key: 'relatorios', label: 'Receber Emails' },
        { key: 'opcoes', label: 'Opções' },
      ],
      representantes: [],
      optionsMultList: [
        { title: 'Prestação de contas' },
        { title: 'Fechamento' },
        { title: 'Demonstrativo' },
        { title: 'Proposta' },
        { title: 'Link Cartão' },
        { title: 'Maquineta' },
      ],
      optionsList: [
        { value: 'Financeiro', text: 'Financeiro' },
        { value: 'Diretoria', text: 'Diretoria' },
        { value: 'Secretaria', text: 'Secretaria' },
        { value: 'TI', text: 'TI' },
      ],
      mask: {
        telefone: {
          delimiters: ['(', ')', ' ', '-', '-'],
          blocks: [0, 2, 0, 5, 4],
          uppercase: true,
        },
      },
      telefoneRepresentantes: [{}],

    }
  },
  created() {
    this.getRepresentantes()
  },
  methods: {
    clearModal() {
      this.cad = {
        relatorios: '',
        departamento: '',
        nome: '',
        telefone: [],
        email: '',
        observacoes: '',
        credor_id: this.$route.params.id,
      }
      this.telefoneRepresentantes = [{}]
    },
    repeateAgain() {
      this.telefoneRepresentantes.push({})
    },
    removeAgain(index) {
      this.telefoneRepresentantes.splice(index, 1)
    },
    updateRepresentante() {
      const formatedPhone = this.cad.telefone.map(item => item.replace(/[^\w\s]/gi, '').replace(/\s/g, ''))
      const body = {
        relatorios: this.cad.relatorios,
        departamento: this.cad.departamento,
        nome: this.cad.nome,
        telefones: formatedPhone,
        email: this.cad.email,
        observacoes: this.cad.observacoes,
        credor_id: this.$route.params.id,
      }
      axios.put(`api/v1/credor_contatos/update/${this.idRepresentante}`, body, {
        headers: {
          Authorization: `Bearer ${localStorage.token}`,
        },
      }).then(() => {
        this.$refs.representanteModal.hide()
        this.editModal = false
        this.getRepresentantes()
        this.$swal({
          title: 'Contato atualizado!',
          icon: 'success',
          timer: 1500,
          showConfirmButton: false,
        })
      })
    },
    detailRepresentante(item) {
      axios.get(`api/v1/credor_contatos/edit/${item.id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.token}`,
        },
      }).then(res => {
        const dt = res.data.dados
        this.telefoneRepresentantes = JSON.parse(dt.telefones)
        this.cad = {
          nome: dt.nome,
          telefone: JSON.parse(dt.telefones),
          email: dt.email,
          observacoes: dt.observacoes,
          credor_id: dt.credor_id,
          departamento: dt.departamento,
          relatorios: dt.relatorios,
        }
        this.idRepresentante = item.id
        this.editModal = true
        this.$refs.representanteModal.show()
      })
    },
    deleteRepresentante(item) {
      this.$swal({
        title: 'Tem certeza?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim quero deletar!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          axios.delete(`api/v1/credor_contatos/delete/${item.id}`, {
            headers: {
              Authorization: `Bearer ${localStorage.token}`,
            },
          }).then(() => {
            this.getRepresentantes()
            this.$swal({
              title: 'Contato Deletado!',
              icon: 'success',
              timer: 1500,
              showConfirmButton: false,
            })
          })
            .catch(() => {
              this.$swal({
                title: 'ERRO',
                icon: 'error',
                text: 'Ocorreu um erro durante a operação!',
              })
            })
        }
      })
    },
    storeRepresentante() {
      const formatedPhone = this.cad.telefone.map(item => item.replace(/[^\w\s]/gi, '').replace(/\s/g, ''))
      const body = {
        relatorios: this.cad.relatorios,
        departamento: this.cad.departamento,
        nome: this.cad.nome,
        telefone: formatedPhone,
        email: this.cad.email,
        observacoes: this.cad.observacoes,
        credor_id: this.$route.params.id,
      }
      axios.post('api/v1/credor_contatos/store', body, {
        headers: {
          Authorization: `Bearer ${localStorage.token}`,
          accept: 'application/json',
        },
      }).then(() => {
        this.$refs.representanteModal.hide()
        this.getRepresentantes()
        this.$swal({
          title: 'Contato Cadastrado!',
          icon: 'success',
          text: 'Novo cadastro adicionado!',
          timer: 1500,
          showConfirmButton: false,
        })
      })
        .catch(() => {
          this.$swal({
            title: 'ERRO!',
            icon: 'error',
            text: 'Ocorreu um erro durante o cadastro!',
            customClass: {
              confimButton: 'btn btn-danger',
            },
          })
        })
    },
    getRepresentantes() {
      this.representantes = []
      axios.get(`api/v1/credor_contatos/${this.$route.params.id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.token}`,
          accept: 'application/json',
        },
      }).then(res => {
        this.representantes = res.data.dados
      })
    },
  },
}
</script>
