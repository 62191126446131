<template>
  <div>
    <b-row>
      <b-col>
        <section
          id="dashboard-ecommerce"
        >
          <b-card
            v-if="dadosGraficos"
            :title="`Remessas Total - R$ ${valorBr(totalRemessa)}`"
          >
            <vue-apex-charts
              type="line"
              height="350"
              :options="chartOptions"
              :series="series"
            />
          </b-card>
        </section>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {
  BRow,
  BCol,
  BCard,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import api from '../../../../../../axios-auth'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    VueApexCharts,
  },
  props: {
    generalData: {
      type: Number,
      default: () => {},
    },
  },
  data() {
    return {
      dadosGraficos: false,
      load: true,
      totalRemessa: 0,
      series: [{
        name: 'Q° de Clientes',
        type: 'column',
        data: [],
        color: '#ff6900',
      }, {
        name: 'Q° de Operações',
        type: 'column',
        data: [],
        color: '#6610f2',
      }, {
        name: 'Valor Nominal',
        type: 'line',
        data: [],
        color: '#00d084',
      }],
      chartOptions: {
        chart: {
          height: 350,
          type: 'line',
          stacked: false,
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          width: [1, 1, 4],
        },
        title: {
          text: '',
          align: 'left',
          offsetX: 110,
        },
        xaxis: {
          categories: [],
        },
        yaxis: [
          {
            axisTicks: {
              show: true,
            },
            axisBorder: {
              show: true,
              color: '#ff9f43',
            },
            labels: {
              style: {
                colors: '#008FFB',
              },
            },
            title: {
              text: 'Q° de Clientes',
              style: {
                color: '#008FFB',
              },
            },
            tooltip: {
              enabled: true,
            },
          },
          {
            seriesName: 'Q° de Clientes',
            opposite: true,
            axisTicks: {
              show: true,
            },
            axisBorder: {
              show: true,
              color: '#6610f2',
            },
            labels: {
              style: {
                colors: '#00E396',
              },
            },
            title: {
              text: 'Q° de Operações',
              style: {
                color: '#00E396',
              },
            },
          },
          {
            seriesName: 'Valor',
            opposite: true,
            axisTicks: {
              show: true,
            },
            axisBorder: {
              show: true,
              color: '#00d084',
            },
            labels: {
              style: {
                colors: '#FEB019',
              },
            },
            title: {
              text: 'Valor (em Real)',
              style: {
                color: '#FEB019',
              },
            },
          },
        ],
        tooltip: {
          fixed: {
            enabled: true,
            position: 'topLeft', // topRight, topLeft, bottomRight, bottomLeft
            offsetY: 30,
            offsetX: 60,
          },
        },
        legend: {
          horizontalAlign: 'left',
          offsetX: 40,
        },
      },
    }
  },
  created() {
    this.getGraficoRemessa()
  },
  methods: {
    getGraficoRemessa() {
      const body = {
        credores: [this.generalData],
      }
      api.post('api/v1/credores/totalizadores/base/cobranca/geral', body, {
        headers: {
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
          accept: 'application/json',
        },
      }).then(res => {
        const dt = res.data.dados
        this.dadosGraficos = true

        dt.map(cliente => {
          this.series[0].data.push(cliente.total_clientes)
        })
        dt.map(operacoes => {
          this.series[1].data.push(operacoes.total_operacoes)
        })
        dt.map(valor => {
          this.series[2].data.push(this.valorBr(valor.total))
        })
        dt.map(ano => {
          this.chartOptions.xaxis.categories.push(ano.ano)
        })
        dt.map(soma => {
          this.totalRemessa += parseFloat(soma.total)
        })
      })
    },
  },
}
</script>
  <style lang="scss">
  @import '@core/scss/vue/pages/dashboard-ecommerce.scss';
  @import '@core/scss/vue/libs/chart-apex.scss';
  </style>
