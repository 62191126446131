<template>
  <div>
    <div class="pie-text text-center">
      <h2 class="font-weight-bolder">
        <!-- $23,994 -->
      </h2>
    </div>

    <!-- echart -->
    <app-echart-doughnut :series="series" @chartClick="handleClick" />

  </div>
</template>

<script>
// import { BCard } from 'bootstrap-vue'
import AppEchartDoughnut from '@core/components/charts/echart/AppEchartDoughnut.vue'

export default {
  components: {
    // BCard,
    AppEchartDoughnut,
  },
  props: {
    data: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      series: [
        {
          name: '',
          type: 'pie',
          radius: ['50%', '70%'],
          avoidLabelOverlap: false,
          color: ['#fc8452', '#9a60b4', '#3ba272', '#2c9aff', '#94ff00'],
          label: {
            show: true,
          },
          labelLine: {
            show: false,
          },
          data: [],
        },
      ],
    }
  },
  async created() {
    this.series[0].data = this.data
    // const { series: dataSeries, categories: dataCategories } = this
    // this.barChart.series[0].data = dataSeries
    // this.barChart.chartOptions.xaxis.categories = dataCategories
  },
  methods: {
    handleClick(data) {
      this.$emit('chartClick', data)
    },
  },
}
</script>

<style lang="scss">
.card-body{
  position: relative;
  .pie-text{
    width: 105px;
    position:absolute;
    margin: auto;
    left: 0;
    right: 0;
    top: 44%;
    bottom: 0;
  }
  }
</style>
