<template>
  <b-card no-body>
    <!-- chart -->
    <b-card-body v-if="dadosCobranca">
      <chartjs-component-bar-chart
        :height="400"
        :data="latestBarChart.data"
        :options="latestBarChart.options"
      />
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardBody,
} from 'bootstrap-vue'
import { $themeColors } from '@themeConfig'
import ChartjsComponentBarChart from './charts-components/ChartjsComponentBarChart.vue'
import api from '../../../../../../axios-auth'

const chartColors = {
  primaryColorShade: '#836AF9',
  yellowColor: '#ffe800',
  successColorShade: '#28dac6',
  warningColorShade: '#ffe802',
  warningLightColor: '#FDAC34',
  infoColorShade: '#299AFF',
  greyColor: '#4F5D70',
  blueColor: '#2c9aff',
  blueLightColor: '#84D0FF',
  greyLightColor: '#EDF1F4',
  tooltipShadow: 'rgba(0, 0, 0, 0.25)',
  lineChartPrimary: '#666ee8',
  lineChartDanger: '#ff4961',
  labelColor: '#6e6b7b',
  grid_line_color: 'rgba(200, 200, 200, 0.2)',
}

export default {
  components: {
    BCard,
    BCardBody,
    ChartjsComponentBarChart,
  },
  props: {
    general: {
      type: Number,
      default: () => {},
    },
  },
  data() {
    return {
      ano: [],
      total: [],
      dadosCobranca: false,
      somaCobranca: 0,
      latestBarChart: {
        data: {
          labels: [],
          datasets: [
            {
              data: [],
              backgroundColor: chartColors.successColorShade,
              borderColor: 'transparent',
            },
          ],
        },
        options: {
          elements: {
            rectangle: {
              borderWidth: 2,
              borderSkipped: 'bottom',
            },
          },
          responsive: true,
          maintainAspectRatio: false,
          responsiveAnimationDuration: 500,
          legend: {
            display: false,
          },
          tooltips: {
            // Updated default tooltip UI
            shadowOffsetX: 1,
            shadowOffsetY: 1,
            shadowBlur: 8,
            shadowColor: chartColors.tooltipShadow,
            backgroundColor: $themeColors.light,
            titleFontColor: $themeColors.dark,
            bodyFontColor: $themeColors.dark,
          },
          scales: {
            xAxes: [
              {
                display: true,
                gridLines: {
                  display: true,
                  color: chartColors.grid_line_color,
                  zeroLineColor: chartColors.grid_line_color,
                },
                scaleLabel: {
                  display: false,
                },
                ticks: {
                  fontColor: chartColors.labelColor,
                },
              },
            ],
            yAxes: [
              {
                display: true,
                gridLines: {
                  color: chartColors.grid_line_color,
                  zeroLineColor: chartColors.grid_line_color,
                },
                ticks: {
                  stepSize: 100,
                  min: 0,
                  max: 0,
                  fontColor: chartColors.labelColor,
                },
              },
            ],
          },
        },
      },
    }
  },
  created() {
    this.getGraficoCobranca()
  },
  methods: {
    getGraficoCobranca() {
      const body = {
        credores: [this.general],
      }
      api.post('api/v1/credores/totalizadores/base/cobranca', body, {
        headers: {
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
          accept: 'application/json',
        },
      }).then(res => {
        const dt = res.data.dados
        this.dadosCobranca = true
        // GRAFICO COBRANÇA
        dt.map(item => {
          this.total.push(parseFloat(item.total).toFixed(2))
        })
        this.latestBarChart.data.datasets[0].data = this.total

        dt.map(item => {
          this.ano.push(item.ano)
        })
        dt.map(soma => {
          this.somaCobranca += parseFloat(soma.total)
        })
        this.latestBarChart.data.labels = this.ano

        const maxNumber = Math.max(...this.total)
        const newMaxNumber = maxNumber * 1.1

        this.latestBarChart.options.scales.yAxes[0].ticks.max = newMaxNumber
        this.$emit('somaCobranca', this.somaCobranca)
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
