<template>
  <b-card>
    <app-echart-bar
      :option-data="option"
    />
  </b-card>
</template>

<script>
import { BCard } from 'bootstrap-vue'
import AppEchartBar from '@core/components/charts/echart/AppEchartBar.vue'

export default {
  components: {
    BCard,
    AppEchartBar,
  },
  props: {
    dataYaxis: {
      type: Array,
      default: () => {},
    },
    series: {
      type: Array,
      default: () => {},
    },
  },
  data() {
    return {
      option: {
        xAxis: [
          {
            type: 'value',
          },
        ],
        yAxis: [
          {
            type: 'category',
            data: this.dataYaxis,
            splitLine: { show: false },
          },
        ],
        grid: {
          left: '100px',
          right: '30px',
          bottom: '30px',
        },
        series: this.series,
      },
    }
  },
}
</script>
