<template>
  <div>
    <b-card title="Clientes">
      <!-- table -->
      <b-row>
        <b-col
          cols="12"
          class="my-1"
        >
          <b-row align-h="between">
            <b-col lg="1">
              <b-form-group
                class="mb-0"
              >
                <b-form-select
                  id="perPageSelect"
                  v-model="perPage"
                  size="sm"
                  :options="[10, 15, 20]"
                />
              </b-form-group>
            </b-col>
            <b-col
              lg="8"
              class="d-flex justify-content-end"
            >
              <b-form-group>
                <div class="d-flex align-items-center">
                  <label class="mr-1">Pesquisar</label>
                  <b-form-input
                    v-model="filter"
                    placeholder="Pesquisar"
                    type="text"
                    class="d-inline-block"
                  />
                </div>
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>

        <!-- table -->
        <b-col cols="12">
          <b-table
            hover
            striped
            bordered
            responsive
            :current-page="currentPage"
            :per-page="perPage"
            :filter="filter"
            :fields="fields"
            :items="rows"
            @row-clicked="onRowClick"
          />
          <b-pagination
            v-model="currentPage"
            :per-page="perPage"
            :total-rows="totalRows"
            align="center"
            size="sm"
            class="my-2"
          />
        </b-col>
      </b-row>
    </b-card>

  </div>
</template>

<script>
import {
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BTable,
  BCol,
  BRow,
  BCard,
} from 'bootstrap-vue'
import store from '@/store/index'

export default {
  components: {
    BCol,
    BRow,
    BPagination,
    BFormGroup,
    BTable,
    BFormInput,
    BFormSelect,
    BCard,
  },
  props: {
    clientes: {
      type: Array,
      default: () => {},
    },
  },
  data() {
    return {
      perPage: 10,
      currentPage: 1,
      totalRows: 1,
      dir: false,
      fields: [
        { key: 'nome', label: 'Nome' },
        { key: 'cpf_cnpj', label: 'CPF/CNPJ' },
      ],
      rows: [],
      filter: '',
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  mounted() {
    this.totalRows = this.clientes.length
  },

  created() {
    // this.rows = this.clientes
    this.clientes.map(dt => {
      this.rows.push({
        id: dt.id,
        nome: dt.nome,
        cpf_cnpj: this.cpfCNPJ(dt.cpf_cnpj),
      })
    })
  },
  methods: {
    onRowClick(item) {
      this.$router.push({ name: 'cliente-view', params: { id: item.id } })
    },
  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
