<template>
  <div>
    <b-row>
      <b-col>
        <section
          id="dashboard-ecommerce"
        >
          <b-card
            v-if="dadosGraficos"
            :title="`Faturamento Total - R$ ${valorBr(totalFaturamento)}`"
          >
            <vue-apex-charts
              type="line"
              height="350"
              :options="chartOptionsFaturamento"
              :series="seriesFaturamento"
            />
          </b-card>
        </section>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
} from 'bootstrap-vue'

import VueApexCharts from 'vue-apexcharts'

import api from '../../../../../../axios-auth'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    VueApexCharts,
  },
  props: {
    generalData: {
      type: Number,
      default: () => {},
    },
  },
  data() {
    return {
      dadosGraficos: false,
      loadFaturamentoMes: true,
      totalFaturamento: 0,
      seriesFaturamento: [{
        name: 'Q° de Clientes',
        type: 'column',
        data: [],
        color: '#ff6900',
      }, {
        name: 'Q° de Parcelas',
        type: 'column',
        data: [],
        color: '#6610f2',
      }, {
        name: 'Valor Realizado',
        type: 'line',
        data: [],
        color: '#00d084',
      }],
      chartOptionsFaturamento: {
        chart: {
          height: 350,
          type: 'line',
          stacked: false,
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          width: [1, 1, 4],
        },
        title: {
          text: '',
          align: 'left',
          offsetX: 110,
        },
        xaxis: {
          categories: [],
        },
        yaxis: [
          {
            axisTicks: {
              show: true,
            },
            axisBorder: {
              show: true,
              color: '#ff9f43',
            },
            labels: {
              style: {
                colors: '#008FFB',
              },
            },
            title: {
              text: 'Q° de Clientes',
              style: {
                color: '#008FFB',
              },
            },
            tooltip: {
              enabled: true,
            },
          },
          {
            seriesName: 'Q° de Clientes',
            opposite: true,
            axisTicks: {
              show: true,
            },
            axisBorder: {
              show: true,
              color: '#6610f2',
            },
            labels: {
              style: {
                colors: '#00E396',
              },
            },
            title: {
              text: 'Q° de Parcelas',
              style: {
                color: '#00E396',
              },
            },
          },
          {
            seriesName: 'Valor Realizado',
            opposite: true,
            axisTicks: {
              show: true,
            },
            axisBorder: {
              show: true,
              color: '#00d084',
            },
            labels: {
              style: {
                colors: '#FEB019',
              },
            },
            title: {
              text: 'Valor Realizado',
              style: {
                color: '#FEB019',
              },
            },
          },
        ],
        tooltip: {
          fixed: {
            enabled: true,
            position: 'topLeft', // topRight, topLeft, bottomRight, bottomLeft
            offsetY: 30,
            offsetX: 60,
          },
        },
        legend: {
          horizontalAlign: 'left',
          offsetX: 40,
        },
      },
    }
  },

  created() {
    this.getGraficoFaturamento()
  },

  methods: {
    getGraficoFaturamento() {
      const body = {
        credores: [this.generalData],
      }
      api.post('api/v1/credores/faturamento/geral', body, {
        headers: {
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
          accept: 'application/json',
        },
      }).then(res => {
        const dt = res.data.dados
        this.dadosGraficos = true

        dt.map(cliente => {
          this.seriesFaturamento[0].data.push(cliente.total_clientes)
        })
        dt.map(parcelas => {
          this.seriesFaturamento[1].data.push(parcelas.total_parcelas)
        })
        dt.map(valor => {
          this.seriesFaturamento[2].data.push(this.valorBr(valor.total))
        })
        dt.map(ano => {
          this.chartOptionsFaturamento.xaxis.categories.push(ano.ano)
        })
        dt.map(soma => {
          this.totalFaturamento += parseFloat(soma.total)
        })
      })
    },
  },
}

</script>

<style lang="scss">
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
</style>
