<template>
  <div>
    <b-card title="Remessas">
      <!-- search input -->
      <div class="custom-search d-flex justify-content-end">
        <b-form-group>
          <div class="d-flex align-items-center">
            <label class="mr-1">Pesquisar</label>
            <b-form-input
              v-model="searchTerm"
              type="text"
              class="d-inline-block"
            />
          </div>
        </b-form-group>
      </div>
      <div>
        <b-table
          ref="table"
          striped
          hover
          bordered
          responsive
          :filter="searchTerm"
          :per-page="perPage"
          :current-page="currentPage"
          :fields="columns"
          :items="rows"
          :sort-by="'data_entrada'"
          :sort-desc="true"
          @row-clicked="onRowClick"
        >
          <template v-slot:cell(data_entrada)="data">
            {{ new Date(data.value).toLocaleDateString() }}
          </template>
        </b-table>
        <b-pagination
          v-model="currentPage"
          :per-page="perPage"
          :total-rows="totalRows"
          align="center"
          size="sm"
          class="my-0"
        />
      </div>
    </b-card>

  </div>
</template>
<script>
import {
  BTable,
  BPagination,
  BFormGroup,
  BFormInput,
  BCard,
} from 'bootstrap-vue'
import store from '@/store/index'

export default {
  components: {
    BTable,
    BPagination,
    BFormGroup,
    BFormInput,
    BCard,
  },
  props: {
    remessas: {
      type: Array,
      default: () => {},
    },
  },
  data() {
    return {
      perPage: 10,
      totalRows: 1,
      currentPage: 1,
      dir: false,
      columns: [
        {
          label: 'ID',
          key: 'id',
        },
        {
          label: 'Data Entrada',
          key: 'data_entrada',
        },
        {
          label: 'Número',
          key: 'numero_remessa',
        },
        {
          label: 'Dt Remessa',
          key: 'data_remessa',
        },
      ],
      rows: [],
      searchTerm: '',
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  created() {
    this.orderColumnsByDataEntrada()
    this.rows = this.remessas
    this.totalRows = this.remessas.length
  },
  mounted() {
    this.$refs.table.sortBy(this.columns[1], 'desc')
  },
  methods: {
    orderColumnsByDataEntrada() {
      const compareByDataEntrada = (a, b) => {
        const dateA = new Date(a.key === 'data_entrada' ? a.label : '')
        const dateB = new Date(b.key === 'data_entrada' ? b.label : '')
        return dateB - dateA
      }
      this.columns.sort(compareByDataEntrada)
    },
    onRowClick(item) {
      this.$router.push({ name: 'remessa-view', params: { id: item.id } })
    },
  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
