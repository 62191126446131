<template>
  <e-charts
    ref="line"
    autoresize
    :options="option"
    theme="theme-color"
    auto-resize
  />
</template>

<script>
import ECharts from 'vue-echarts'
import 'echarts/lib/component/tooltip'
import 'echarts/lib/component/legend'
import 'echarts/lib/chart/pie'
import theme from './theme.json'

ECharts.registerTheme('theme-color', theme)

export default {
  components: {
    ECharts,
  },
  props: {
    series: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      option: {
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b}: {c} ({d}%)',
        },
        legend: {
          left: 10,
          bottom: '0',
        },
        series: this.series,
      },
    }
  },
  mounted() {
    this.$refs.line.chart.on('click', this.handleClick)
  },
  methods: {
    handleClick(params) {
      // Acessa as informações do item clicado
      // eslint-disable-next-line
      const dataIndex = params.dataIndex // Índice do item clicado
      const dataItem = params.data // Dados completos do item clicado

      // Faça o que for necessário com as informações capturadas
      this.$emit('chartClick', dataItem)
    },
  },
}
</script>
